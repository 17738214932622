import { graphql } from "gatsby";
/* eslint-disable no-useless-escape */
import React from "react";
import Layout from "../components/Layout/index";
// import InputMask from 'react-input-mask'
import Pagina from "../components/Pagina";
// import axios from 'axios'

import cartaoLoja from "../images/icons/cartao-spani/opcao.svg";
import cartaoCalendario from "../images/icons/cartao-spani/prazo.svg";
// Assets
import cartaoDesconto from "../images/icons/cartao-spani/promo.svg";

const CartaoSpani = ({ data }) => {
	const breadC = {
		visible: true,
		items: [
			{ path: "/", name: "Home" },
			{ path: "/cartao-spani/", name: "Cartão Spani" },
		],
	};

	// const [formState, setFormState] = useState([])
	// const [successMessage, setSuccessMessage] = useState('')
	// const [errorMessage, setErrorMessage] = useState('')

	// function validaEmail () {
	//   return !!formState.emailCartao.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)
	// }

	// function validaCPF () {
	//   return !!formState.cpf.match(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/)
	// }

	// function validaCep () {
	//   return !!formState.cep.match(/^\d{5}-\d{3}$/)
	// }

	// function handleFormValidation () {
	//   setSuccessMessage(false)
	//   if (formState && Object.keys(formState).length < 11) {
	//     setErrorMessage('Preencha todos os campos obrigatórios')
	//   } else if (formState.nomeCartao.match(/[0-9]/gi)) {
	//     setErrorMessage('Nomes não podem conter números!')
	//   } else if (!formState.nomeCartao || formState.nomeCartao.length < 1) {
	//     setErrorMessage('O campo nomeCartao é obrigatório!')
	//   } else if (!formState.date || formState.date.length < 1) {
	//     setErrorMessage('O campo data é obrigatório!')
	//   } else if (!formState.cep || formState.cep.length < 1) {
	//     setErrorMessage('O campo cep é obrigatório!')
	//   } else if (!formState.endereco || formState.endereco.length < 1) {
	//     setErrorMessage('O campo endereço é obrigatório!')
	//   } else if (!formState.numero || formState.numero.length < 1) {
	//     setErrorMessage('O campo número é obrigatório!')
	//   } else if (!formState.bairro || formState.bairro.length < 1) {
	//     setErrorMessage('O campo bairro é obrigatório!')
	//   } else if (!formState.cidade || formState.cidade.length < 1) {
	//     setErrorMessage('O campo cidade é obrigatório!')
	//   } else if (!formState.estado || formState.estado.length < 1) {
	//     setErrorMessage('O campo estado é obrigatório!')
	//   } else if (!validaEmail()) {
	//     setErrorMessage('Digite um Email Válido!')
	//   } else if (!validaCPF()) {
	//     setErrorMessage('CPF Inválido!')
	//   } else if (formState.telefone && formState.telefone.length < 9) {
	//     setErrorMessage('Telefone Inválido!')
	//   } else if (!formState.celularCartao || formState.celularCartao.length < 9) {
	//     setErrorMessage('Celular Inválido!')
	//   } else if (formState.telefoneRecado && formState.telefoneRecado.length < 9) {
	//     setErrorMessage('Telefone Inválido!')
	//   } else if (!validaCep()) {
	//     setErrorMessage('CEP Inválido!')
	//   } else {
	//     return true
	//   }
	//   return false
	// }

	// async function enviarMensagem (event) {
	//   event.preventDefault()
	//   if (handleFormValidation()) {
	//     const payload = {
	//       nomeCartao: formState.nomeCartao,
	//       cpf: formState.cpf,
	//       date: formState.date,
	//       emailCartao: formState.emailCartao,
	//       telefone: formState.telefone,
	//       celularCartao: formState.celularCartao,
	//       telefoneRecado: formState.telefoneRecado,
	//       cep: formState.cep,
	//       endereco: formState.endereco,
	//       numero: formState.numero,
	//       bairro: formState.bairro,
	//       complemento: formState.complemento,
	//       cidade: formState.cidade,
	//       estado: formState.estado
	//     }
	//     try {
	//       await axios.post('https://api.clouddog.com.br/web-site/contato', payload)
	//       console.log(payload)
	//       setErrorMessage(false)
	//       setSuccessMessage(<>Cadastro realizado com sucesso!</>)
	//     } catch (error) {
	//       setSuccessMessage(false)
	//       setErrorMessage(<>Não foi possível concluir o cadastro. <br/> Por favor, verifique os dados e tente novamente! </>)
	//     }
	//   }
	// }

	// const setInput = (e) => {
	//   setFormState({ ...formState, [e.target.name]: e.target.value })
	// }

	return (
		<Layout breadCrumb={breadC}>
			<Pagina pagina={data.pagina} />
			<section className="container mt-4">
				<div className="row">
					<div className="col-12">
						<h1>Cartão Spani Atacadista</h1>
						<span className="font-size-15 text-black">
							<b>
								Dirija-se a loja mais próxima e faça seu cartão hoje mesmo!*
							</b>
						</span>
						<br />
						<span className="font-size-15 text-black">
							<b>
								Na hora você poderá aproveitar todas os benefícios do seu
								cartão!
							</b>
						</span>
					</div>
				</div>
			</section>
			<section className="container my-5">
				<div className="row">
					<div className="col-lg-4 d-flex flex-column justify-content-center mb-3 mb-lg-0">
						<img
							src={cartaoCalendario}
							alt="figura de um calendário com fundo artistico"
							height="100px"
						/>
						<p className="mt-4 text-center">Mais prazo para pagar</p>
					</div>
					<div className="col-lg-4 d-flex flex-column justify-content-center mb-3 mb-lg-0">
						<img
							src={cartaoDesconto}
							alt="figura de uma conta com fundo artistico"
							height="100px"
						/>
						<p className="mt-4 text-center">
							Promoções e descontos
							<br /> exclusivos
						</p>
					</div>
					<div className="col-lg-4 d-flex flex-column justify-content-center mb-3 mb-lg-0">
						<img
							src={cartaoLoja}
							alt="figura da fachada de uma loja com fundo artistico"
							height="100px"
						/>
						<p className="mt-4 text-center">
							Opção de pagamento da fatura
							<br /> em loja e o limite restabelece na
							<br /> mesma hora
						</p>
					</div>
				</div>
			</section>
			<section className="container mt-4">
				<p className="text-black">
					É necessário trazer pessoalmente o comprovante de renda e comprovante
					de endereço na loja para efetivar a aquisição. Documentos necessários:
					RG e CPF ou CNH originais.
				</p>
				<p className="text-black pt-2">
					* Sujeito a análise de crédito. Aceito em todas as lojas SPANI
					Atacadista.
				</p>
			</section>

			<div className="mt-5">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h2 className="text-black mb-0">
								Solicite agora o seu Cartão Spani Atacadista
							</h2>
						</div>
						<div className="col-12 pt-3 mb-3 text-center">
							<a
								href="https://oferta.vocedm.com.br/cartaospani"
								target="_blank"
								rel="noopener noreferrer"
								className="bg-yellow border-radius100px py-2 px-0 d-flex w-100 text-uppercase justify-content-center"
							>
								<p className="mb-0 font-openSans-semibold">
									clique aqui para solicitar o seu cartão via whatsapp
								</p>
							</a>
						</div>
					</div>
					{/* <form className="w-100 card pt-3 pb-3 form-spani">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb-2">
                  <label htmlFor="nomeCartao" className="mb-0">Nome Completo*</label>
                  <input type="text" id="nomeCartao" className="w-100" name="nomeCartao" onChange={(e) => setInput(e)} />
                </div>
                <div className="col-lg-4 mb-2">
                  <label htmlFor="cpf" className="mb-0 mt-2">CPF*</label>
                  <InputMask mask={'999.999.999-99'} placeholder="000.000.000-00" type="text" id="cpf" className="w-100" name="cpf" onChange={(e) => setInput(e)} />
                </div>
                <div className="col-lg-3 mb-2">
                  <label htmlFor="date" className="mb-0 mt-2">Data*</label>
                  <input type="date" id="date" className="w-100" name="date" onChange={(e) => setInput(e)} />
                </div>
                <div className="col-lg-5 mb-2">
                  <label htmlFor="emailCartao" className="mb-0 mt-2">E-mail*</label>
                  <input type="text" id="emailCartao" className="w-100" name="emailCartao" onChange={(e) => setInput(e)} />
                </div>
                <div className="col-lg-4 mb-2">
                  <label htmlFor="telefone" className="mb-0 mt-2">Telefone Residencial</label>
                  <input type="text" id="telefone" className="w-100" name="telefone" onChange={(e) => setInput(e)} />
                </div>
                <div className="col-lg-4 mb-2">
                  <label htmlFor="celularCartao" className="mb-0 mt-2">Celular*</label>
                  <input type="text" id="celularCartao" className="w-100" name="celularCartao" onChange={(e) => setInput(e)} />
                </div>
                <div className="col-lg-4 mb-2">
                  <label htmlFor="telefoneRecado" className="mb-0 mt-2">Telefone para Recado</label>
                  <input type="text" id="telefoneRecado" className="w-100" name="telefoneRecado" onChange={(e) => setInput(e)} />
                </div>
                <div className="col-lg-3 mb-2">
                  <label htmlFor="cep" className="mb-0">CEP*</label>
                  <InputMask mask={'99999-999'} placeholder="00000-000" type="text" id="cep" className="w-100" name="cep" onChange={(e) => setInput(e)} />
                </div>
                <div className="col-lg-7 mb-2">
                  <label htmlFor="endereco" className="mb-0">Endereço*</label>
                  <input type="text" id="endereco" className="w-100" name="endereco" onChange={(e) => setInput(e)} />
                </div>
                <div className="col-lg-2 mb-2">
                  <label htmlFor="numero" className="mb-0">Número*</label>
                  <input type="text" id="numero" className="w-100" name="numero" onChange={(e) => setInput(e)} />
                </div>
                <div className="col-lg-3 mb-2">
                  <label htmlFor="bairro" className="mb-0">Bairro*</label>
                  <input type="text" id="bairro" className="w-100" name="bairro" onChange={(e) => setInput(e)} />
                </div>
                <div className="col-lg-3 mb-2">
                  <label htmlFor="complemento" className="mb-0">Complemento</label>
                  <input type="text" id="complemento" className="w-100" name="complemento" onChange={(e) => setInput(e)} />
                </div>
                <div className="col-lg-4 mb-2">
                  <label htmlFor="cidade" className="mb-0">Cidade*</label>
                  <input type="text" id="cidade" className="w-100" name="cidade" onChange={(e) => setInput(e)} />
                </div>
                <div className="col-lg-2 mb-2">
                  <label htmlFor="estado" className="mb-0">Estado*</label>
                  <input type="text" id="estado" className="w-100" name="estado" onChange={(e) => setInput(e)} />
                </div>
                <div className="col-12 mt-4">
                  <button className="btn btn-yellow d-flex w-100 text-uppercase justify-content-center font-openSans-extrabold" onClick={(e) => (enviarMensagem(e))}>solicitar meu cartão</button>
                </div>
                {
                  !!errorMessage && (
                    <div className="col-lg-12">
                      <div className="d-flex bg-danger align-items-center justify-content-center py-2 px-4 my-1 rounded">
                        <p className="shadow rounded-circle text-white text-center align-self-center mb-0 mr-3"
                          style={{ width: '25px', height: '25px' }} >!</p>
                        <p className="text-center text-white mb-0 font-weight-bold w-75 fonterror">
                          {errorMessage}
                        </p>
                      </div>
                    </div>
                  )
                }
                {
                  !!successMessage && (
                    <div className="col-md-12">
                      <div className="d-flex align-items-center justify-content-center bg-success rounded p-2 my-3">
                        <p
                          className="border text-white rounded-circle mb-0 mr-3 font-size-15 d-flex justify-content-center align-items-center"
                          style={{ width: '45px', height: '45px' }} >✔</p>
                        <p className="font-weight-bold text-white text-center font-size-1125 m-0">
                          {successMessage}
                        </p>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="col-12 pt-4">
              <p className="font-size-08">(*) Campos obrigatórios</p>
            </div>
          </form> */}
				</div>
			</div>
		</Layout>
	);
};

export default CartaoSpani;

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/cartao-spani/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    pageSummary
    tags
    possuiBanner
    card {
      localFile {
        publicURL
      }
    }
    desktop {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    mobile {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 414, height: 310, layout: CONSTRAINED)
        }
      }
    }
  }
}
`;
